import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { Customer } from "@farmact/model/src/model/Customer";
import Field from "@farmact/model/src/model/Field";
import { query, where } from "firebase/firestore";
import { useCallback } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

type UseFieldsOptions = {
    companyId?: string;
};
type UseFieldsReturn = {
    fields: Field[];
    loading: boolean;
};

export function useFields(fieldIds: string[], options: UseFieldsOptions = {}): UseFieldsReturn {
    const { companyId } = options;

    const stableFieldIds = useStableIdArray(fieldIds);

    const queryGenerator = useCallback(
        (fieldIds: string[]) => {
            return query(Firebase.instance().getAllFields({ companyId }), where("id", "in", fieldIds));
        },
        [companyId]
    );

    const [fields, loading] = useMergedQueryData(stableFieldIds, queryGenerator);

    return {
        fields,
        loading,
    };
}

export function useCustomerFields(customerId: Customer["id"] | undefined, companyId: AppCompany["id"] | undefined) {
    return useCollectionData(
        customerId
            ? Firebase.instance().getAllFieldsForCustomer({
                  customerId,
                  companyId: companyId,
              })
            : undefined,
        [customerId]
    );
}
