import { Customer } from "./Customer";
import Field from "./Field";
import { LoadingOrUnloadingPoint } from "./LoadingOrUnloadingPoint";
import { Marker } from "./Marker";
import { OperatingUnit } from "./OperatingUnit";
import { MapStructureStatus, MapStructuresSequence } from "./Order";
import { Track } from "./Track";

export abstract class OrderBasics {
    id: string;
    archived: boolean;
    operatingUnitId: OperatingUnit["id"] | null;
    name: string;
    note: string;
    customerIds: Customer["id"][];
    /**
     * ISO date string
     */
    plannedStartDateTime: string | null;
    /**
     * ISO date string
     */
    plannedEndDateTime: string | null;
    fieldIds: Field["id"][];
    loadingPointIds: LoadingOrUnloadingPoint["id"][];
    unloadingPointIds: LoadingOrUnloadingPoint["id"][];
    markers: Marker[];
    trackIds: Track["id"][];
    fieldStatus: { [fieldId: Field["id"]]: MapStructureStatus };
    trackStatus: { [trackId: Track["id"]]: MapStructureStatus };
    markerStatus: { [markerId: Marker["id"]]: MapStructureStatus };
    loadingOrUnloadPointStatus: { [pointId: LoadingOrUnloadingPoint["id"]]: MapStructureStatus };
    mapStructuresSequence: MapStructuresSequence;
    isMapStructuresSequenceEnabled: boolean;

    constructor(initialValues?: Partial<OrderBasics>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.operatingUnitId = initialValues?.operatingUnitId ?? null;
        this.name = initialValues?.name ?? "";
        this.note = initialValues?.note ?? "";
        this.customerIds = initialValues?.customerIds ?? [];
        this.plannedStartDateTime = initialValues?.plannedStartDateTime ?? null;
        this.plannedEndDateTime = initialValues?.plannedEndDateTime ?? null;
        this.fieldIds = initialValues?.fieldIds ?? [];
        this.trackIds = initialValues?.trackIds ?? [];
        this.loadingPointIds = initialValues?.loadingPointIds ?? [];
        this.unloadingPointIds = initialValues?.unloadingPointIds ?? [];
        this.markers = initialValues?.markers ?? [];
        this.fieldStatus = initialValues?.fieldStatus ?? {};
        this.trackStatus = initialValues?.trackStatus ?? {};
        this.markerStatus = initialValues?.markerStatus ?? {};
        this.loadingOrUnloadPointStatus = initialValues?.loadingOrUnloadPointStatus ?? {};
        this.mapStructuresSequence = initialValues?.mapStructuresSequence ?? [
            ...this.fieldIds,
            ...this.markers.map(marker => marker.id),
            ...this.trackIds,
            ...this.loadingPointIds,
            ...this.unloadingPointIds,
        ];
        this.isMapStructuresSequenceEnabled = initialValues?.isMapStructuresSequenceEnabled ?? false;
    }
}
